<template>
  <div class="inner_app">
    <div
      :class="[
        'wrap_firfin',
        { 'wrap_full' : isBodyFull },
      ]"
      :style="cssProps">
      <Header
        v-if="isHeader"
        :bgColor="bgColor"
        :title="pageName"
        :isTitleOpacity="isTitleOpacity"
        :isTitleOpacityActive="isTitleOpacityActive"
        :searchData="searchData"
        :isBackBtn="isBackBtn"
        :isRightTextMenu="isRightTextMenu"
        :rightTextMenu="rightTextMenu"
        :isCloseBtn="isCloseBtn"
        @onClickBack="onClickBack()"
        @onClickClose="onClickClose()"
        @onSearch="value => $emit('onSearch',value)"
        @onClickRightMenu="$emit('onClickRightMenu')" />
      <Content
        :topPadding="topPadding"
        :bottomPadding="bottomPadding"
        :leftRightPadding="leftRightPadding">
        <slot />
      </Content>
    </div>
    <slot name="popup" />
  </div>
</template>

<script>
import Header from '@/components/layout/header/Header';
import Content from '@/components/layout/content/Content';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';

export default {
  name:'PageWithLayout',
  mixins: [ InterFaceMixin ],
  components:{
    Header,
    Content,
  },
  props:{
    bgColor:{
      type: String,
      default: '#fff'
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    title: String,
    isTitleOpacity: {
      type: Boolean,
      default: false
    },
    isTitleOpacityActive: {
      type: Boolean,
      default: false
    },

    searchData: Object,
    isBackBtn: {
      type: Boolean,
      default: false
    },
    isBackWebEnd: {
      type: Boolean,
      default: false
    },
    backRouteName: String,
    backRouteParams: Object,
    backRouteQuery: Object,
    backPage: String,
    backPageData: Object,

    isRightTextMenu: {
      type: Boolean,
      default: false
    },
    rightTextMenu: String,
    isCloseBtn: {
      type: Boolean,
      default: false
    },
    isCloseWebEnd: {
      type: Boolean,
      default: false
    },
    closeRouteName: String,
    closeRouteParams: Object,
    closeRouteQuery: Object,
    closePage: String,

    topPadding: Number,
    bottomPadding: Number,
    leftRightPadding: Number,
    isBodyFull:{
      type: Boolean,
      default: false
    },
  },
  beforeMount(){
    this.setBgColor();
  },
  computed:{
    isHeader(){
      return this.isTitle || this.isBackBtn || this.isCloseBtn
    },
    pageName(){
      return this.isTitle ? this.title || this.$route.meta.pageTitle : '';
    },
    cssProps(){
      let rObj = {
        '--topPadding' : this.isHeader ? '54px' : '0',
        '--bgColor' : this.bgColor
      };
      return rObj
    }
  },
  methods:{
    setBgColor(){
      document.body.style.backgroundColor = this.bgColor
    },
    onClickBack(){
      if(this.backPage){
        if(this.backPageData){
          // let data = this.backPageData;
          // const andInterfaceFunction = () => {
          //   data.pageName = this.backPage;
          //   window.FirFinInterface.pageMove(data);
          // };
          // const iosInterfaceFunction = () => {
          //   data.name = 'pageMove';
          //   data.pageName = this.backPage;
          //   window.webkit.messageHandlers.FirFinInterface.postMessage(data);
          // };
          // this.customSendInterface(andInterfaceFunction, iosInterfaceFunction);
          this.pageMove(this.backPage,this.backPageData)
        }else{
          this.pageMove(this.backPage)
        }
        return;
      }
      if(this.isBackWebEnd){
        this.webEnd();
      }else if( this.backRouteName ){
        this.goRoute(this.backRouteName, this.backRouteParams, this.backRouteQuery);

      }else{
        this.$emit('onClickBack')
      }
    },
    onClickClose(){
      if(this.closePage){
        this.pageMove(this.closePage)
        return;
      }
      if(this.isCloseWebEnd){
        this.webEnd();
      }else if( this.closeRouteName ){
        this.goRoute(this.closeRouteName, this.closeRouteParams, this.closeRouteQuery);
      }else{
        this.$emit('onClickClose')
      }
    },
    goRoute(routeName, params, query){
      let routeInfo = { name: routeName };
      if(params){
        routeInfo.params = params;
      }
      if(query){
        routeInfo.query = query;
      }
      this.$router.push(routeInfo);
      return;
    },
  }
}
</script>

<style>
.wrap_firfin{
  height:100%;
  padding-top:var(--topPadding);
  padding-bottom:constant(safe-area-inset-bottom);
  padding-bottom:env(safe-area-inset-bottom);
  box-sizing:border-box;
}

.wrap_full #mArticle{padding-bottom:0}
.wrap_full .cont_firfin{position:relative;min-height:100vh;box-sizing:border-box;}

.wrap_full .head_firfin + .cont_firfin{min-height:calc(100vh - 54px);}
</style>