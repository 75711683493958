<template>
  <InputBase
    :class="[{ disabled: isDisabled, tf_error: isError, tf_has_util : isCurrentPassword || isRemoveBtn, tf_valued : value != '', tf_focus : isFocus}, inputSizeClassName ]"
    :style="{ maxWidth: maxWidth }">
      <!-- <p v-if="(isError && errorDesc) || (isReferenceError && referenceErrorDesc)" class="desc_error">{{ isReferenceError && referenceErrorDesc ? referenceErrorDesc : errorDesc  }}</p> -->
      <div class="inner_input">
        <slot name="tfFrontIcon"/>
        <input 
          v-model="input" 
          :type="isPassword || type" 
          :inputmode="inputmode"
          :pattern="pattern"
          :placeholder="placeholder" 
          :maxLength="maxLength"
          :disabled="isDisabled"
          :autocomplete="autocomplete ? 'on' : 'new-password'"
          ref="input"
          v-on:keyup.13="onKeyUpEnter"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput" />
        <div class="area_util" v-if="( isCurrentPassword || isRemoveBtn ) && !isDisabled">
          <!-- <button
            v-if="isCurrentPassword && !isDisabled"
            type="button"
            :class="['btn_preview',{ acitve : isPassword == 'text' }]"
            @click="onClickPrevie">
            <IconSvg v-if="isPassword === 'text'" iconName="preview_active" :icoSize="16" iconColor="#555"/>
            <IconSvg v-else iconName="preview" :icoSize="16" iconColor="#999"/>
          </button> -->
          <button
            v-if="isRemoveBtn && !isDisabled"
            type="button"
            class="btn_remove"
            @click="onClickRemove()">
            <IconSvg iconName="remove" :icoSize="20" iconColor="#A6A4A4"/>
          </button>
      </div>
    </div>
    <p v-if="isCount && maxLength" class="desc_count"><em :class="{ on : countingValue != 0 }">{{ countingValue }}</em> &#47; {{ maxLength }}</p>
  </InputBase>
</template>

<script>
import InputBase from '@/components/common/input/InputBase';
import IconSvg from '@/components/common/icon/IconSvg';
import { autoValid } from '@/utils/validateUtils'

export default {
  name: 'Input',
  components: {
    InputBase,
    IconSvg,
  },
  props: {
    value: [String, Number],
    valueType: String,
    referenceValue:String,
    unReferenceValue:String,
    type: {
      type: String,
      default: 'text'
    },
    inputmode: String,
    pattern:String,
    autocomplete:{
      type: Boolean,
      default: false
    },
    size: String,
    placeholder: {
      type: String,
      default: null
    },
    maxWidth: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
    isBlockUnValueType: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    errorDesc: String,
    referenceErrorDesc: String,
    maxLength: Number,
    isCount: {
      type: Boolean,
      default: false
    },
    isFocusOnMount: {
      type: Boolean,
      default: false
    },
    isRemoveBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    input: {
      get() {
        let valueType = this.valueType;
        if(this.value){
          const { convertedValue, formatVaild, referenceVaild} = autoValid(this.value, valueType, this.referenceValue, this.unReferenceValue);
          this.setValidValue( convertedValue, formatVaild, referenceVaild);
        }else{
          this.$emit('update:isError', false);
          this.isReferenceError = false;
        }

        if( this.value && this.maxLength && this.value.length > this.maxLength ) {
          this.$emit("onChange", this.value.slice( 0, this.maxLength ));

          this.$emit("update:value", this.value.slice( 0, this.maxLength ));
        }
        return this.value;
      },
      set(value) {
        if(this.valueType === 'number'){
          value = Number(value);
        }
        this.$emit("update:value", value);
      }
    },
    countingValue() {
      return this.value.length;
    },
    inputSizeClassName(){
      if(this.size){
        return 'tf_'+this.size;
      }else{
        return '';
      }
    },

  },
  data(){
    return{
      isFocus: false,
      isCurrentPassword:false,
      isPassword:false,
      isReferenceError:false,
    }
  },
  mounted() {
    if( this.isFocusOnMount ) {
      this.$refs.input.focus();
    }
    if( this.type == 'password' ){
      this.isCurrentPassword = true;
      this.isPassword = 'password';
    }
  },
  methods: {
    setValidValue( convertedValue, formatVaild, referenceVaild ){
      const isValid = convertedValue == this.value;
      if( this.value && this.isBlockUnValueType && !isValid ){
        this.$emit('onChanege', convertedValue);
        this.$emit('update:value', convertedValue);
      }
      this.$emit('update:isError', !formatVaild || !isValid);
      this.isReferenceError = !referenceVaild;
    },
    onClickRemove(){
      // console.log('onClickRemove');
      this.$emit("update:value", '');
      this.$refs.input.focus();
    },
    blur() {
      // 외부에서 호출 될 메서드.
      this.$refs.input.blur();
      this.$emit("onBlur");
    },
    onFocus( e ) {
      this.isFocus = true;
      this.$emit("onFocus", e);
    },
    onBlur( e ) {
      this.isFocus = false;
      this.$emit("onBlur", e);
      this.$emit("update:blur", e);
    },
    onKeyUpEnter( e ) {
      this.blur();

      this.$emit("onEnterKey", e);
    },
    onInput(e) {
      this.$emit("onInput", e);
    },
    onClickPrevie(){
      this.isPassword = this.isPassword == 'text' ? 'password' : 'text';
    }
  }
}
</script>
