<template>
  <div class="head_firfin" :style="{ 'background-color' : bgColor }">
    <div class="inner_head">
      <!-- 타이틀 -->
      <h1 v-if="title" :class="['tit_head', titOpacityClass ]">{{ title }}</h1>
      <!-- * 왼쪽 * -->
      <!-- 뒤로가기 버튼 -->
      <button
        v-if="isBackBtn"
        class="btn_left btn_back"
        @click="$emit('onClickBack')">
        <IconSvg
          iconName="back"
          :size="24"
          iconColor="#232323"/>
      </button>
      <!-- * 오른쪽 * -->
      <!-- 텍스트 버튼 -->
      <button
        v-if="isRightTextMenu"
        class="btn_right btn_right_menu"
        @click="$emit('onClickRightMenu')">
        {{ rightTextMenu }}
      </button>
      <!-- 닫기 버튼 -->
      <button
        v-if="isCloseBtn"
        class="btn_right btn_close"
        @click="$emit('onClickClose')">
        <IconSvg
          iconName="cross"
          :size="24"
          iconColor="#232323"/>
      </button>
      <!-- 검색 버튼 -->
      <button
        v-if="searchDataSync && !searchDataSync.isSearchOpen"
        class="btn_right btn_search"
        @click="onClickSearchOpen()">
        <IconSvg
          iconName="search"
          :size="24"
          iconColor="#232323"/>
      </button>
      <!-- 검색 활성화 -->
      <template 
        v-if="searchDataSync && searchDataSync.isSearchOpen">
        <!-- 검색 취소 버튼 -->
        <button
          class="btn_right btn_search_close"
          @click="onClickSearchClose()">취소</button>
        <!-- 검색 입력 -->
        <div class="box_search">
          <Input
            class="tf_search"
            :placeholder="searchDataSync.placeholder"
            :value.sync="searchDataSync.value"
            @onEnterKey="$emit('onSearch',searchDataSync.value)">
            <template v-slot:tfFrontIcon>
              <IconSvg
                iconName="search"
                :size="24"
                iconColor="#232323"/>
            </template>
          </Input>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';
import Input from '@/components/common/input/Input';

export default {
  name:'Header',
  props:{
    bgColor: String,
    title: String,
    isTitleOpacity: Boolean,
    isTitleOpacityActive: Boolean,
    
    searchData: Object,
    isBackBtn: Boolean,
    isRightTextMenu:Boolean,
    rightTextMenu:String,
    isCloseBtn: Boolean,
  },
  components:{
    IconSvg,
    Input
  },
  computed:{
    searchDataSync:{
      get(){
        return this.searchData;
      },
      set(val){
        this.$emit('update:searchData',val)
      },
    },
    titOpacityClass(){
      if(this.title && this.isTitleOpacity){
        return this.isTitleOpacityActive ? 'tit_opcity_on' : 'tit_opcity_off'
      }else{
        return ''
      }
    }
  },
  methods:{
    onClickSearchOpen(){
      this.searchDataSync.isSearchOpen = true;
    },
    onClickSearchClose(){
      this.searchDataSync.isSearchOpen = false;
      this.searchDataSync.value = '';
      this.$emit('onSearch','')
    }
  }
}
</script>

<style scoped>
.head_firfin{position:fixed;top:0;left:0;right:0;z-index:200;height:54px;padding:15px 56px;box-sizing:border-box}
.head_lemontree .inner_head{padding:15px 64px;text-align:center}

.head_firfin .tit_head{display:block;font-weight:600;font-size:16px;line-height:24px;color:#111;text-align:center;}
.head_firfin .tit_head.tit_opcity_off{opacity:0;transition:opacity 0.4s}
.head_firfin .tit_head.tit_opcity_on{opacity:1;transition:opacity 0.4s}

.head_firfin .btn_left{position:absolute;top:0;left:0;padding:15px 20px}
.head_firfin .btn_right{position:absolute;top:0;right:0;padding:15px 20px}
.head_firfin .btn_right_menu,
.head_firfin .btn_search_close{font-weight:700;font-size:16px;line-height:24px;color:#232323}
.head_firfin .box_search{position:absolute;top:0;left:20px;right:64px}
</style>