<template>
  <div
    id="firfinContent"
    :class="[
      'cont_firfin',
      `cont_${$route.name.toLowerCase()}`
    ]"
    :style="cssProps">
    <div id="mArticle">
      <slot />
    </div>
  </div>
</template>

<script>
export default{
  name:'Content',
  props:{
    topPadding: Number,
    bottomPadding: Number,
    leftRightPadding: Number,
  },
  computed:{
    cssProps(){
      const top = this.topPadding ? `${this.topPadding}px` : '0';
      const bottom = this.bottomPadding ? `${this.bottomPadding}px` : '0';
      const leftRight = this.leftRightPadding ? `${this.leftRightPadding}px` : '0';
      let rObj = {
        '--padding' : `${top} ${leftRight} ${bottom}`
      };
      return rObj
    }
  }
}
</script>

<style scoped>
.cont_firfin{padding:var(--padding)}
.cont_walletmainchild{background-color:#fff}
.cont_couponlist{background-color:#F7F6FA}

.cont_websessionerror{height:calc(100vh - 54px)}
.cont_websessionerror #mArticle{height:100%}
</style>